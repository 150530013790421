/* eslint-disable */
import * as bootstrap from 'bootstrap';

////////////
// Counter
////////////
document.addEventListener('DOMContentLoaded', function () {
  const counters = document.querySelectorAll('.counter');

  if (counters.length > 0) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(function (entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const counter = entry.target;
          const targetValue = parseInt(counter.getAttribute('data-counter'));
          const duration = 2000; // 2 seconds
          const startTime = performance.now();
          const numberElement = counter.querySelector('.number'); // Select the number element

          const updateCounter = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const currentCount = Math.floor(progress * targetValue);

            numberElement.textContent = currentCount;

            if (progress < 1) {
              requestAnimationFrame(updateCounter);
            } else {
              numberElement.textContent = targetValue; // Ensure the final value is set
            }
          };

          requestAnimationFrame(updateCounter);
          observer.unobserve(counter);
        }
      });
    }, options);

    counters.forEach(counter => {
      observer.observe(counter);
    });
  }
});


////////////
// Scroll Form Pop
////////////
window.setupAlpinePopup = function() {
      const triggerDiv = document.getElementById('triggerAlpineForm');
      const alpinePopup = document.getElementById('alpinePopup');

      if (triggerDiv && alpinePopup) {
          const modalAlpine = new bootstrap.Modal(alpinePopup);

          // Intersection Observer to show modal when the trigger div is in view
          const observer = new IntersectionObserver(entries => {
              entries.forEach(entry => {
                  if (entry.isIntersecting) {
                      modalAlpine.show();
                      localStorage.setItem('popup-alpinePopuphide', 'true');
                      observer.disconnect(); // Stop observing after showing the modal
                  }
              });
          }, {
              root: null, // Use the viewport as the root
              threshold: 0.1 // Trigger when 10% of the target is visible
          });

          observer.observe(triggerDiv);

          // Create the fixedAlpineTrigger element on page load if not already present
          const existingTrigger = document.getElementById('fixedAlpineTrigger');
          if (!existingTrigger) {
              const fixedAlpineTrigger = document.createElement('div');
              fixedAlpineTrigger.id = 'fixedAlpineTrigger';
              fixedAlpineTrigger.classList.add('fixed-alpine-trigger', 'd-none');
              fixedAlpineTrigger.innerHTML = `
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gift" viewBox="0 0 16 16">
                  <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zM1 4v2h6V4zm8 0v2h6V4zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5z"/>
                </svg>`;

              fixedAlpineTrigger.addEventListener('click', function() {
                  modalAlpine.toggle();
                  this.classList.toggle('modal-active');
              });

              document.body.appendChild(fixedAlpineTrigger);
          }

          // Event listeners for modal show and hide events
          alpinePopup.addEventListener('shown.bs.modal', () => {
              const fixedAlpineTrigger = document.getElementById('fixedAlpineTrigger');
              if (fixedAlpineTrigger) {
                  fixedAlpineTrigger.classList.remove('d-none');
                  fixedAlpineTrigger.classList.add('modal-active');
              }
          });

          alpinePopup.addEventListener('hidden.bs.modal', () => {
              const fixedAlpineTrigger = document.getElementById('fixedAlpineTrigger');
              if (fixedAlpineTrigger) {
                  fixedAlpineTrigger.classList.remove('modal-active');
              }
          });
      }
}


